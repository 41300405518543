import env from "./../env";

const Config = {
  apiUrl: env.baseUrl+'/api',
  apiBoUrl: env.baseUrl+'/bo-api',
  baseUrl: env.baseUrl,
  mrSize:{
    S: "S",
    M: "M",
    L: "L",
    XL: "XL",
    XXL: "XXL",
    XXXL: "3XL",
  },
  statusMahasiswa:{
    A:'Aktif',
    L:'Lulus',
    N:'Tidak Aktif',
  },
  Akreditasi:{
    A:'A',
    B:'B',
    C:'C',
    D:'D',
    E:'E',
    F:'F',
    G:'G',
    H:'H',
    I:'I',
  }
}

export default Config