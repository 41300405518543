<script>
import Vue from 'vue';
import GlobalVue from '@helper/Global.vue';
import moment from 'moment';
import Gen from '@helper/Gen';
import FOGen from '@helper/FOGen';
import Croppa from 'vue-croppa';
import swal from 'sweetalert';
import VTooltip from 'v-tooltip'
import {VueMasonryPlugin} from 'vue-masonry';
import {vueTopprogress} from 'vue-top-progress'

/* Vue plugins */
Vue.use(VueMasonryPlugin)
Vue.use(Croppa);
Vue.use(vueTopprogress)

moment.locale("id")
global.moment = moment

/* CSS */

import "@frontend/css/custom.scss"
import "@frontend/css/responsive.scss"

/* JS */
import "@plugins/jquery/dist/jquery.min.js"
import "@plugins/jquery-validation/jquery-validation.js"
import "@helper/general_function.js"
import "@plugins/extension.js"
import "@frontend/js/main.js"

/* Vue Component */
Vue.component("ImagePreview",()=>import("@backend/components/ImagePreview.vue"))
Vue.component("Component404",()=>import("@frontend/components/Component404.vue"))
Vue.component("VInfo", ()=>import("@frontend/components/VInfo.vue"))

global.$ = global.jQuery
global.Gen = Gen
global.App.user = null

export default {
    name:"FoMain",
    extends: GlobalVue,
    async mounted(){
        window.history.scrollRestoration = 'manual'
        global.FOGen = FOGen
        /* CSS */
        Gen.loadCss("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Roboto+Slab:400,600,700,900&display=swap")
        Gen.loadCss("/frontend/css/bootstrap.css")
        Gen.loadCss("/frontend/css/style.css?v=6.0")
        Gen.loadCss("/frontend/css/swiper.css")
        Gen.loadCss("/frontend/css/dark.css")
        Gen.loadCss("/frontend/css/font-icons.css")
        Gen.loadCss("/frontend/css/animate.css")
        Gen.loadCss("/frontend/css/magnific-popup.css")
        Gen.loadCss("/frontend/css/custom_l9.css?v=9.0")
        Gen.loadCss("/frontend/css/responsive.css?v=9.0")
        Gen.loadCss("/frontend/demos/nonprofit/css/fonts.css")
        Gen.loadCss("/frontend/demos/nonprofit/nonprofit.css?v=1.0")
        Gen.loadCss("/frontend/css/responsive_l9.css?v=2.0")

        /* JS */    
        await Gen.loadScript("/frontend/js/jquery.js")
        await Gen.loadScript("/frontend/js/plugins.js")
        await Gen.loadScript("/frontend/js/functions.js?v=1.1")
    
        global.$ = global.jQuery

        /* Ready Loaded */
        $(document).ready(()=>{
            this.$root.ready = true
        })

        /* Load Variable */
        this.refreshVariableFo()

        var self = this
        $(document).on("click", "a", function(e){
            if($(this).is("[target]")) return;
            if($(this).attr("data-lightbox")) return;
            if($(this).attr("href")=="javascript:;") return;
            if($(this).attr("href")[0]=="#") return;
            if($(this).attr("href").indexOf(location.origin)>-1){
                e.preventDefault();
                self.$router.push($(this).attr("href").replace(location.origin, ""))
            }
            if($(this).attr("href").indexOf(":")<0){
                e.preventDefault();
                if(self.$route.fullPath!=$(this).attr("href")) self.$router.push($(this).attr("href"))
            }
        })
        $(document).on('keydown', '.form-control', function (e) {
            if (e.which === 32 && e.target.selectionStart === 0) {
                return false;
            }
        });
    },
    components: {
        vueTopprogress
    },
    watch: {
        $route(){
            if(typeof $ != 'undefined') $("html,body").scrollTop(0)
            $("#primary-menu > ul").removeClass('d-block')
        },
        hl(v){
            this.refreshVariableFo()
        }
    },
}

$(document).scroll(function() {
    var y = $(this).scrollTop();
    if (y > 200) {
        $('.bottom_navigation').fadeIn();
    } else {
        $('.bottom_navigation').fadeOut();
    }
});
</script>

<template>
    <div id="FoMain" :class="{ready:$root.ready}">
        <router-view/>
    </div>
</template>